<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockProductlook = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "content";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockProductlook;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slot = getSlotContent("productlook");
</script>

<template>
  <div class="bg-maas-background-grey-light">
    <CmsGenericElement :content="slot" />
  </div>
</template>
